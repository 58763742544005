import { Slot } from '@radix-ui/react-slot'
import * as React from 'react'

import { css } from '#styled-system/css'

// const buttonVariants = cva(
// 	'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors outline-none focus-visible:ring-2 focus-within:ring-2 ring-ring ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
// 	{
// 		variants: {
// 			variant: {
// 				default: 'bg-primary text-primary-foreground hover:bg-primary/80',
// 				destructive:
// 					'bg-destructive text-destructive-foreground hover:bg-destructive/80',
// 				outline:
// 					'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
// 				secondary:
// 					'bg-secondary text-secondary-foreground hover:bg-secondary/80',
// 				ghost: 'hover:bg-accent hover:text-accent-foreground',
// 				link: 'text-primary underline-offset-4 hover:underline',
// 			},
// 			size: {
// 				default: 'h-10 px-4 py-2',
// 				wide: 'px-24 py-5',
// 				sm: 'h-9 rounded-md px-3',
// 				lg: 'h-11 rounded-md px-8',
// 				pill: 'px-12 py-3 leading-3',
// 				icon: 'h-10 w-10',
// 			},
// 		},
// 		defaultVariants: {
// 			variant: 'default',
// 			size: 'default',
// 		},
// 	},
// )

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	asChild?: boolean
	variant?:
		| 'default'
		| 'destructive'
		| 'outline'
		| 'secondary'
		| 'ghost'
		| 'link'
	size?: 'default' | 'wide' | 'sm' | 'lg' | 'pill' | 'icon'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return (
			<Comp
				className={css({
					width: '100%',
					borderRadius: '4px',
					backgroundColor: 'primary9',
					paddingInline: '16px',
					paddingBlock: '8px',
					color: 'white',
					cursor: 'pointer',
					_hover: {
						backgroundColor: 'primary10',
					},
					_focus: {
						backgroundColor: 'primary10',
					},
				})}
				ref={ref}
				{...props}
			/>
		)
	},
)
Button.displayName = 'Button'

export { Button }
